/*FONT FAMILY*/
/** Generated by FG **/
@font-face {
	font-family: 'SFProDisplay';
	src: url('fonts/SFProDisplay/SF-Pro-Display-Regular.eot');
	src: local('☺'), url('fonts/SFProDisplay/SF-Pro-Display-Regular.woff') format('woff'), url('fonts/SFProDisplay/SF-Pro-Display-Regular.ttf') format('truetype'), url('fonts/SFProDisplay/SF-Pro-Display-Regular.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'SFProDisplay';
	src: url('fonts/SFProDisplay/SFProDisplay-Bold.eot');
	src: local('☺'), url('fonts/SFProDisplay/SFProDisplay-Bold.woff') format('woff'), url('fonts/SFProDisplay/SFProDisplay-Bold.ttf') format('truetype'), url('fonts/SFProDisplay/SFProDisplay-Bold.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/*font-family: 'Noto Serif', serif;*/