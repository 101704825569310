.placeholder {
  background: #222222;
  opacity: .1;
  animation: fading 1.5s infinite;
  border-radius: 4px;
  font-size: 0 !important;
}

.box-placeholder {
  font-size: 0;
  line-height: 0;
  .thumb_art{background:#fff}
  .thumb_big,.thumb_art{
    .tag{display: none;}
  }
  .thumb_img,.title_news,.description,.tag,.number-info,.name,.ava,.status {
    background: #222222;
    opacity: .1;
    animation: fading 1.5s infinite;
    border-radius: 4px;
    overflow: hidden;
    font-size: 0!important;
    *{font-size: 0!important;}
    img,.ic-live:before,svg{display: none!important;}
  }
  .status {
    width: 100%!important;
  }
  .icon_thumb{display: none;}
  .title_news{height: 40px;}
  .description{height: 40px;}
  .tag,.number-info,.status {height: 20px;width: 60%;}
  .xem_them{line-height: 1.4;}
}

.box-placeholder .text {
  display: inline-block;
  background-color: #444;
  height: 12px;
  border-radius: 100px;
  margin: 5px 0;
  min-width: 100px;
  opacity: .1;
  animation: fading 1.5s infinite;
}

.box-placeholder .text:first-child {
  margin-top: 0;
}

.box-placeholder .text:last-child {
  margin-bottom: 0;
}

.box-placeholder .text.link {
  background-color: var(--blue);
  opacity: .4;
}

.box-placeholder .text.line {
  width: 100%;
}

.box-placeholder .text.category {
  width: 100px;
  margin-bottom: 10px;
}

.box-placeholder h4.text {
  height: 20px;
  margin: 3px 0;
  opacity: .2;
}

.box-placeholder .title-news .text {
  margin: 0;
}

.box-placeholder.item-news-common .title-news+.thumb-art+.text {
  width: calc(100% - 100px);
}

.box-placeholder.item-news-common .title-news+.thumb-art+.text+.text {
  width: calc(100% - 130px);
  clear: both;
}

.box-placeholder.item-news-common .title-news+.text {
  width: 100%;
}

.box-placeholder.item-news-common .title-news+.text+.text {
  width: calc(100% - 30px);
  clear: both;
}

.box-placeholder .title-box-category .text {
  height: 20px;
}

.box-placeholder.box-gocnhin .title-news .text {
  height: 17px;
  width: calc(100% - 105px);
}

.box-placeholder.box-gocnhin .title-news+.text {
  width: calc(100% - 140px);
}

.box-placeholder.box-slide-topic {
  padding: 0;
}

.box-placeholder.box-slide-topic .text {
  height: 25px;
  border-radius: 100px;
  margin-top: 11px;
}

.section_video .box-placeholder .text.thumb-video {
  border-radius: 0;
  margin-bottom: 0;
}

.section_video .box-placeholder .title-news {
  line-height: 100%;
}

.section_video .box-placeholder .title-news .text {
  height: 17px;
}

.section_video .box-placeholder .box-scroll-video .thumb-art+.text {
  width: calc(100% - 130px);
}

.section_video .box-placeholder .box-scroll-video .thumb-art+.text+.text {
  width: calc(100% - 160px);
  clear: both;
}

.box-cate-featured-vertical.box-placeholder .sub-news-cate .thumb-art+.text {
  width: calc(100% - 86px);
}

.box-cate-featured-vertical.box-placeholder .sub-news-cate .thumb-art+.text+.text {
  width: calc(100% - 116px);
  clear: both;
}

.box-placeholder .wrap-slide-photo .text {
  height: 503px;
  border-radius: 0;
  background-color: #aaa;
}

.box-ebank-qt .box-placeholder .wrap-slide-bank-qt .text {
  height: 140px;
  border-radius: 0;
  background-color: #aaa;
}

.box-news-other-site .box-placeholder .title-news {
  line-height: 120%;
}

.box-news-other-site .box-placeholder .title-news .text {
  width: calc(100% - 280px);
  height: 17px;
}

.box-news-other-site .box-placeholder .title-news .text+.text {
  width: 20%;
  height: 17px;
}

.box-news-other-site .box-placeholder .title-news+.text {
  width: calc(100% - 280px);
  clear: both;
  margin-top: 10px;
}

.box-placeholder.box-info-company .wrap-slide-business .text {
  height: 210px;
  border-radius: 0;
  background-color: #aaa;
}

.box-placeholder.box-shop-sell .wrap-slide-business .text {
  height: 100px;
  border-radius: 0;
  background-color: #aaa;
}

.box-placeholder.box-shop-sell-vertical .wrap-slide-business .text {
  height: 445px;
  border-radius: 0;
  background-color: #aaa;
}

.box-placeholder.box-wiki-kidlab .wrap-slide-business .text {
  height: 212px;
}

.list-news-subfolder .box-placeholder .title-news {
  line-height: 130%;
}

.list-news-subfolder .box-placeholder .title-news .text {
  width: calc(100% - 260px);
  height: 15px;
}

.list-news-subfolder .box-placeholder .title-news .text+.text {
  width: 100px;
  height: 15px;
}

.list-news-subfolder .box-placeholder .title-news+.text {
  width: calc(100% - 260px);
}

.list-news-subfolder .box-placeholder .title-news+.text+.text {
  width: calc(100% - 260px);
  clear: both;
}

.list-news-subfolder .box-placeholder .title-news+.text+.text+.text {
  width: 100px;
}

.box-news-banner.box-placeholder .content-box-category .text.line {
  height: 56px;
  border-radius: 0;
}

/**PLACEHOLDER LIVE**/
.block-item.box-placeholder .title-block-live {
  margin-top: 20px;
}

.block-item.box-placeholder .title-block-live .text {
  height: 20px;
}

.block-item.box-placeholder .title-block-live .text+.text {
  width: 80%;
}

.block-item.box-placeholder .header-block+.text+.text {
  width: 80%;
  clear: both;
}

.block-item.box-placeholder .header-block+.text+.text+.text {
  width: 60%;
  clear: both;
}

.block-item.box-placeholder .header-block+.text+.text+.text+.text {
  width: 40%;
  clear: both;
  margin-right: 60%;
}

.block-item.box-placeholder .header-block+.text+.text+.text+.text+.text {
  width: 10%;
  clear: both;
}

.block-item.box-placeholder .social-block {
  margin-top: 25px;
}

.block-item.box-placeholder .social-block .text {
  height: 20px;
}

.ds-dienbien .box-placeholder .text {
  height: 17px;
}

.ds-dienbien .box-placeholder .text+.text {
  height: 12px;
}

.banner-ads.box-placeholder .text {
  border-radius: 10px;
  margin: 0;
}

.banner-ads.box-placeholder.banner-height-250 .text {
  height: 250px;
}

.banner-ads.box-placeholder.banner-height-500 .text {
  height: 500px;
}

.banner-ads.box-placeholder.banner-height-600 .text {
  height: 600px;
}

.banner-ads.box-placeholder.banner-height-90 .text {
  height: 90px;
}

.slide-table-tt.box-placeholder .text {
  height: 134px;
  border-radius: 0;
}

/*.col-right-subfolder{
  .box-cate-featured{
    &.box-placeholder{
      .item-news.full-thumb{width: 100%; margin-bottom: 20px;}
      .sub-news-cate{width: 100%; padding-left: 0;}
    }
  }
}*/
@keyframes fading {
  0% {
    opacity: .1;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: .1;
  }
}


@keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp
}