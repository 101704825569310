.header{
    height: 80px;
    background: #fff;
    color: #222;
    .container{
        height: 100%;
        display: flex;
        align-items: center;
    }
    .logo{
        width: 190px;
        margin-right: 26px;
    }
    .gr-tag{
        display: flex;
    }
    .item-tag{
        padding: 7px 25px;
        border: 1px solid #BDBDBD;
        border-radius: 45px;
        margin-right: 16px;
        font-size: 14px;
        color: #222;
    }
    .line{
        display: inline-block;
        width: 0;
        height: 14px;
        border-right: 1px solid #D5D7DA;
        margin: 0 8px;
    }
    .right{
        margin-left: auto;
        align-items: center;
    }
    .date{
        align-items: center;
        .icon-ct{
            margin-right: 5px;
        }
    }
    .weather{
        align-items: center;
        .icon-ct{
            margin-right: 5px;
        }
        sup{
            line-height: 0;
        }
    }
    .link-search{
        font-size: 18px;
    }
    @media(max-width:600px){
        height: 48px;
        .logo{
            width: 106px;
        }
    }
}

/*main nav*/
.main-nav{
    width: 100%;
    float: left;
    background: var(--colormain);
    margin-bottom: 24px;
    z-index: 9;
}
.list-nav{
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff;
    padding: 7px 15px;
    .item-nav{
        font-family: $font-title;
        &.active, &:hover{
            a{
                background: #D55509;
                border-radius: 35px;
                color: #fff;
            }
        }
        &.home{
            .icon-ct{
                font-size: 24px;
            }
            a{
                background: none;
                padding-left: 0;
                @media(max-width:1000px){
                    padding: 3px 9px;
                }
            }
        }
        & > a{
            display: inline-block;
            padding: 3px 18px;
            transition: all .3s ease;
            @media(max-width:1000px){
                padding: 3px 9px;
            }
        }
    }
    .swipe-mode{
        margin-left: auto;
        a{
            display: flex;
            align-items: center;
        }
        &:hover{
            a{
                color: #fff;
            }
        }
        .btn-swipe{
            width: 28px;
            height: 16px;
            padding: 2px;
            background: var(--color-1);
            border-radius: 30px;
            margin-left: 5px;
            display: flex;
            align-items: center;
            position: relative;
            &:before{
                content: '';
                width: 12px;
                height: 12px;
                background: #fff;
                border-radius: 50%;
                position: absolute;
                left: 2px;
                transition: all .3s ease;
            }
        }
    }
}
/*End main nav*/

.darkmode{
    .list-nav .swipe-mode .btn-swipe{
        &:before{left: 14px;}
    }
}